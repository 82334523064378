import { handleQueryResolve } from '../utils';

export default function(name){

  if (typeof name === 'string') {
    return this.query(`
      SELECT
        patient.PatientID,
        patient.FirstName,
        patient.LastName,
        patient.DOB
      FROM
        Fillware.dbo.Patient as patient
      WHERE
        FirstName LIKE @name OR
        LastName LIKE @name 
      ORDER BY
        FirstName, LastName, DOB
      `, [{
        param: 'name',
        type: 'sql.VarChar(50)',
        value: '%' + name + '%' // to support like
    }]).then(handleQueryResolve);

  }

  if (typeof name === 'object') {

    let attrs = [];
    let where = [];
    if (name.firstName) {
      attrs.push({
        param: 'firstName',
        type: 'sql.VarChar(50)',
        value: '%' + name.firstName + '%' // to support like
      });

      where.push('FirstName LIKE @firstName');
    }

    if (name.lastName) {
      attrs.push({
        param: 'lastName',
        type: 'sql.VarChar(50)',
        value: '%' + name.lastName + '%' // to support like
      });
      
      where.push('LastName LIKE @lastName');
    }

    return this.query(`
      SELECT
        patient.PatientID,
        patient.FirstName,
        patient.LastName,
        patient.DOB
      FROM
        Fillware.dbo.Patient as patient
      WHERE
        ${where.join(' AND ')}
      ORDER BY
        FirstName, LastName, DOB
      `, attrs).then(handleQueryResolve);
  }


}